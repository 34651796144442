import Helmet from "react-helmet"
import React from "react"
import Optionaloffer from "@tightrope/optionaloffer"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"


export default function Optional() {
  return(

    <section>
    <Helmet><title>Breaking News Tab - breakingnewstab.com</title>

    </Helmet>
      <Optionaloffer data={Data} siteData={SiteData}></Optionaloffer>
    </section>
  )
}
